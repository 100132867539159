import Noise from '@component/noise'
import BackReload from '@component/pages/backReload'
import ErrorLog from '@component/pages/errorLog'
import IconNotFound from '@icon/not-found.svg'
import getRouter, { URLS } from '@router'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { Suspense } from 'react'

export default function NotFound() {

  return (
    <Noise>
      <div className='py-80 text-center w-550 max-w-full mx-auto'>
        <IconNotFound className='w-80 h-80 md:w-100 md:h-100'></IconNotFound>
        <h1 className='text-lg md:text-2xl mb-20'>Oops! The page you requested was not found.</h1>
        <div className='text-sm md:text-lg'>Perhaps you'd like to go back to the <DisablePrefetchLink href={getRouter(URLS.homepage)} className='text-primary underline'>homepage</DisablePrefetchLink>.</div>
      </div>
      <Suspense>
        <BackReload></BackReload>
        <ErrorLog error='NotFound'></ErrorLog>
      </Suspense>
    </Noise>
  )
}